import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoginPage from "pages/Auth/LoginPage";
import DashboardHome from "pages/Dashboard";
import SideBarLayout from "layout/SideBarLayout";
import PetParents from "pages/Petparent";
import PetProfiles from "pages/petProfile";
import ViewProfile from "./pages/Petparent/ViewProfile";
import ViewPetProfile from "./pages/petProfile/ViewProfile";
import ServiceProvider from "pages/ServiceProvider/ServiceProvider";
import OneServiceProvider from "pages/ServiceProvider/OneServiceProvider";
import { ServiceEdit } from "pages/ServiceProvider/ServiceEdit";
import { AccommodationEdit } from "pages/ServiceProvider/AccommodationEdit";
function App() {
  return (
    <React.Fragment>
      <Toaster
        toastOptions={{
          className: "font-poppins text-base rounded",
          success: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
          error: {
            style: {
              background: "#EBEDF7",
              color: "#1C2135",
              padding: "6px 20px",
            },
          },
        }}
        position="top-right"
      />

      <div className="w-full h-full">
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<SideBarLayout />}>
              <Route
                path="/"
                element={<Navigate to="/dashboard" />}
              />
              <Route path="dashboard" element={<DashboardHome />} />
              <Route path="pet-parent" element={<PetParents />} />
              <Route path="pet-parent/:id" element={<ViewProfile />} />
              <Route path="pet-profile" element={<PetProfiles />} />
              <Route path="pet-profile/:id" element={<ViewPetProfile />} />
              <Route path="service-provider" element={<ServiceProvider />} />
              <Route
                path="service-provider/:id"
                element={<OneServiceProvider />}
              />
              <Route
                path="service-provider/:user_id/:id"
                element={<ServiceEdit />}
              />
              <Route
                path="service-provider/accomm/:user_id/:id"
                element={<AccommodationEdit />}
              />
            </Route>
          </Routes>
        </Router>
      </div>
    </React.Fragment>
  );
}

export default App;
