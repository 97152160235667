import React from "react";

const IconButton = ({ children, onClick, ...props }) => {
  return (
    <button
      onClick={onClick}
      className="bg-faintOrangeBg cursor-pointer text-darkOrangeBg rounded-full flex items-center justify-center h-[30px] w-[30px]"
    >
      {children}
    </button>
  );
};

export default IconButton;
