/* eslint-disable no-undef */
const config = {
    environment: process.env.REACT_APP_ENV || "production",
    endpoints: {
        prod: "https://api-dev.booppetcare.com",
        dev: "https://boop-dev-xbpsu3dq4q-el.a.run.app",
        local: "http://localhost:8001",
    },
    endpoints_service: {
        prod: "https://boop-pet-portal.booppetcare.com/sign-up?source=admin",
        dev: "https://boop-pet-portal.web.app/sign-up?source=admin",
        local: "http://localhost:3001/sign-up?source=admin",
    },
    getEndpoint() {
        switch (this.environment) {
            case "local":
                return this.endpoints.local;
            case "development":
                return this.endpoints.dev;
            case "production":
                return this.endpoints.prod;
            default:
                return "https://api-dev.booppetcare.com";
        }
    },
    getCustomerEndpoint() {
        switch (this.environment) {
            case "local":
                return this.endpoints_service.local;
            case "development":
                return this.endpoints_service.dev;
            case "production":
                return this.endpoints_service.prod;
            default:
                return "https://boop-pet-portal.booppetcare.com/sign-up?source=admin";
        }
    },
};

export default config;