import React, { useEffect, useState } from "react";
import profilePic from "../../assets/img/profile_pic.png";
import petImg from "../../assets/img/pet_img.png";
import ViewProfileFields from "../../components/Common/ViewProfileFields";
import ViewProfileInfo from "../../components/Common/ViewProfileInfo";
import PetCard from "../../components/Common/PetCard";
import {
  useGetOneParentQuery,
  useAddPetAndParentMutation,
  useGetAllpetsQuery,
} from "store/features/pet_parents/petParentApiSlice";
import { useParams } from "react-router-dom";
import { LinkOffOutlined } from "@mui/icons-material";
import Modal from "components/Common/Modal";
import Input from "components/Common/Input";
import Button from "components/Common/Button";
import toast from "react-hot-toast";
import { Autocomplete, TextField } from "@mui/material";

const ViewProfile = () => {
  const { id } = useParams();

  const [addPetParentModal, setAddPetParentModal] = useState(false);

  const { isLoading, data, refetch } = useGetOneParentQuery(
    new URLSearchParams({ id }),
  );
  const { data: petList } = useGetAllpetsQuery();
  const [addPetAndParent, { isLoading: addLoading, isError, isSuccess }] =
    useAddPetAndParentMutation();

  useEffect(() => {
    if (isSuccess) {
      refetch();
      toast.success("Pet added successfully");
      setAddPetParentModal(false);
    }

    if (isError) {
      toast.error("Failed to add pet");
    }
  }, [isSuccess, isError, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const addPetToParent = () => {
    return (
      <Modal
        state={addPetParentModal}
        width={500}
        handleState={() => setAddPetParentModal((prevState) => !prevState)}
      >
        <p className="font-poppins font-semibold text-[28px] capitalize">
          Add Pet to Parent
        </p>
        <form
          onSubmit={(e) => {
            e?.preventDefault();
            const payload = {
              pet_id: e.target.pet_id.value,
              parent_id: id,
            };
            addPetAndParent(payload);
          }}
        >
          <div className="flex flex-col space-y-2">
            <Input
              type={"hidden"}
              id={"pet_id"}
              name="pet_id"
              label={"Pet ID"}
              placeholder="Enter Pet ID..."
            />

            <Autocomplete
              disablePortal
              options={petList?.pets?.rows?.map((pet) => ({
                id: pet.id,
                label: `${pet?.name} ${pet?.pet_id} `,
                rendering: <div>
                  <p>{`${pet?.name} - ${pet?.gender} - ${pet?.pet_id}`}</p>
                </div>,
              }))}
              sx={{
                padding: "0px",
              }}
              onChange={(e, value) => {
                document.getElementById("pet_id").value = value?.id;
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props}>
                    {option.rendering}
                  </li>
                )
              }}
              renderInput={(params) => <TextField {...params} label="Pet" />}
            />

            <div className="flex flex-col space-y-2">
              <Button type="submit">{addLoading ? "Adding.." : "Add"}</Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() => setAddPetParentModal(false)}
              >
                Go Back
              </div>
            </div>
          </div>
        </form>
      </Modal>
    );
  };

  return (
    <div className="flex flex-col space-y-6">
      <p>Pet Parent Profile</p>
      {addPetToParent()}
      {isLoading ? (
        "Loading..."
      ) : (
        <>
          {/* FIRST SECTION WITH PROFILE PIC AND OTHER INFO */}
          <div className="grid grid-cols-4">
            <div>
              <img
                src={data?.data?.image_id ? data?.data?.file?.url : profilePic}
                alt="profile_pic"
                className="h-[240px] w-[240px] "
              />
            </div>
            <div className="col-span-3">
              <div className="flex flex-col space-y-2">
                <div className="grid grid-cols-3">
                  <ViewProfileFields
                    title="ID"
                    value={data?.data?.id?.split("-")[0]?.toUpperCase()}
                  />
                </div>
                <div className="grid grid-cols-2 gap-3">
                  <ViewProfileFields
                    title="First Name"
                    value={data?.data?.first_name}
                  />
                  <ViewProfileFields
                    title="Last Name"
                    value={data?.data?.last_name}
                  />
                </div>
                <div className="grid grid-cols-3 gap-3">
                  <ViewProfileFields
                    title="Zip Code"
                    value={data?.data?.pincode}
                  />
                  <ViewProfileFields title="State" value={data?.data?.state} />
                  <ViewProfileFields title="City" value={data?.data?.city} />
                </div>
              </div>
            </div>
          </div>

          {/* SECOND SECTION WITH ADDRESS AND OTHER INFO */}
          <div className="grid md:grid-cols-4 grid-cols-1 gap-2">
            <ViewProfileFields
              title="Mobile Number"
              value={data?.data?.phone}
            />
            <ViewProfileFields
              title="Emergency Number"
              value={data?.data?.phone_emergency}
            />
            <ViewProfileFields title="Number of Bookings" value={0} />{" "}
            {/* TODO: make this dynamic */}
            <ViewProfileFields
              title="Pets Associated"
              value={data?.data?.pets?.length || 0}
            />
          </div>

          {/* THIRD SECTION WITH CONTACT INFO */}
          <div className="grid grid-cols-2 gap-3">
            <ViewProfileInfo
              title={"Address 1"}
              contents={data?.data?.address_1}
            />
            <ViewProfileInfo
              title={"Address 2"}
              contents={data?.data?.address_2}
            />
            <ViewProfileInfo
              title={"Related Pets"}
              topActionButton={
                <button
                  className="bg-faintOrangeBg cursor-pointer text-darkOrangeBg rounded-full flex items-center justify-center h-[30px] w-[30px]"
                  onClick={() => setAddPetParentModal(true)}
                >
                  <LinkOffOutlined style={{ height: 15, width: 15 }} />
                </button>
              }
              contents={
                <div className="flex flex-row space-x-4">
                  {data?.data?.pets?.map((single_pet, index) => (
                    <PetCard
                      img={
                        single_pet?.profile_photo
                          ? single_pet?.file?.url
                          : petImg
                      }
                      name={single_pet?.name}
                    />
                  ))}
                  {/* <PetCard img={petImg} name={"Tommy"} />
                  <PetCard img={petImg} name={"Tommy"} />
                  <PetCard img={petImg} name={"Tommy"} /> */}
                </div>
              }
            />
            <ViewProfileInfo title={"Feedback"} />
          </div>

          {/* <div className="flex flex-col gap-4">
            <p className="font-semibold">Documents</p>
            <div className="grid grid-cols-4 gap-3">
              <ViewProfileInfo title={"Document"} />
              <ViewProfileInfo title={"Document"} />
              <ViewProfileInfo title={"Document"} />
              <ViewProfileInfo title={"Document"} />
              <ViewProfileInfo title={"Document"} />
              <ViewProfileInfo title={"Document"} />
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};

export default ViewProfile;
