import React, { useEffect, useState } from "react";

const CustomToggle = ({
  label1,
  label2,
  label3,
  handleChange,
  value,
  isError,
  errorText,
}) => {
  const [isSelected1, setIsSelected1] = useState(value == label1);
  const [isSelected2, setIsSelected2] = useState(value == label2);
  const [isSelected3, setIsSelected3] = useState(value == label3);

  const handleToggle1 = () => {
    setIsSelected1(true);
    setIsSelected2(false);
    setIsSelected3(false);
    handleChange(label1);
  };

  const handleToggle2 = () => {
    setIsSelected1(false);
    setIsSelected2(true);
    setIsSelected3(false);
    handleChange(label2);
  };
  const handleToggle3 = () => {
    setIsSelected1(false);
    setIsSelected2(false);
    setIsSelected3(true);
    handleChange(label3);
  };
  useEffect(() => {
    setIsSelected1(value == label1);
    setIsSelected2(value == label2);

    setIsSelected3(value == label3);
  }, [value]);
  return (
    <div className="flex flex-col">
      <div className="flex w-[260px] items-center gap-2.5 bg-orange-100 p-1 rounded-lg cursor-pointer hover:bg-orange-200">
        <div
          className={`filter-option flex justify-center items-center w-36 h-10 rounded-md  font-semibold ${
            isSelected1
              ? "bg-orange-400  text-white"
              : "bg-orange-100 text-black"
          }`}
          onClick={handleToggle1}
        >
          {label1}
        </div>
        <div
          className={`filter-option flex justify-center items-center w-36 h-10 rounded-md  font-semibold ${
            isSelected2
              ? "bg-orange-400 text-white"
              : "bg-orange-100 text-black"
          }`}
          onClick={handleToggle2}
        >
          {label2}
        </div>
        {label3 && (
          <div
            className={`filter-option flex justify-center items-center w-36 h-10 rounded-md  font-semibold ${
              isSelected3
                ? "bg-orange-400 text-white"
                : "bg-orange-100 text-black"
            }`}
            onClick={handleToggle3}
          >
            {label3}
          </div>
        )}
      </div>
      {isError && (
        <p className="ml-1 font-normal text-sm text-red-500">{errorText} </p>
      )}
    </div>
  );
};

export default CustomToggle;
