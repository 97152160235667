import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { MdDeleteOutline } from "react-icons/md";

const UploadedFilePlaceholder = ({ name, link, onRemove }) => {
  return (
    <div className="flex flex-row justify-between items-center px-1 py-[4px] rounded-lg text-sm bg-orangeBg/10 text-orangeBg select-none relative overflow-hidden">
      <a href={link} target="_blank">
        <img
          src={link}
          alt={name}
          className="underline h-[5rem] w-[5rem] rounded-lg"
          target="_blank"
          rel="noreferrer"
        ></img>
      </a>
      <MdDeleteOutline
        style={{ color: "red", height: 25, width: 25 }}
        size={30}
        className="cursor-pointer absolute top-2 right-2 bg-white p-1 rounded-lg"
        onClick={onRemove}
      />
    </div>
  );
};

export default UploadedFilePlaceholder;
