import React from "react";
import Modal from "./Modal";
import Button from "./Button";

const Altert = ({ state, handleState, title, content, onConfirm }) => {
  return (
    <Modal handleState={handleState} state={state} width={300} padding={2}>
      <div className="bg-white rounded-lg flex flex-col justify-start items-center">
        <h1 className="font-poppins font-semibold text-[#1C2135] text-lg">
          {title}
        </h1>
        <p className="font-poppins font-normal text-[#1C2135] text-sm mt-2">
          {content}
        </p>
        <div className="flex justify-center mt-4">
          <Button onClick={onConfirm}>Yes</Button>
          <button
            className="w-[100px] h-[40px] bg-[#F8F8F8] text-[#1C2135] font-semibold text-sm rounded-lg focus:outline-none ml-4"
            onClick={handleState}
          >
            No
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Altert;
