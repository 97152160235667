import PropTypes from "prop-types";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Search } from "@mui/icons-material";

const DataTable = ({ columns, data, filterArea }) => {
  /* const tblColumns = useMemo(() => columns, []);
  const tblData = useMemo(() => data, []); */

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canNextPage,
    canPreviousPage,
    state,
    pageOptions,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row gap-1 bg-white items-center lg:w-80 w-full rounded pl-3 h-[45px]">
          <Search style={{ height: "19px" }} className="text-gray-400" />
          <input
            type="text"
            placeholder="Search..."
            className="focus:outline-none placeholder:text-sm placeholder:font-normal text-base font-normal px-2 py-2 w-full  bg-transparent border-none"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e?.target?.value)}
          />
        </div>
        {filterArea}
      </div>
      <div className="rounded-[9px] border border-[#D5D5D5] bg-[#FCFDFD]">
        <table {...getTableProps()} className="w-full text-center">
          <thead className="border-b text-capitalize">
            {
              /* This part of the code is responsible for rendering the table header. Here's a breakdown of
        what it does: */
              headerGroups?.map((headerGroup, index) => (
                <tr
                  key={index}
                  {...headerGroup?.getHeaderGroupProps()}
                  className="capitalize"
                >
                  {headerGroup?.headers?.map((column, col_index) => (
                    <th
                      key={col_index}
                      {...column.getHeaderProps(column?.getSortByToggleProps())}
                      className="font-poppins text-[16px] font-semibold py-[14px]  text-[#4d4d4d] capitalize"
                    >
                      {column?.render("Header")}
                    </th>
                  ))}
                </tr>
              ))
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row?.cells?.map((cell, cell_index) => {
                    return (
                      <td
                        key={cell_index}
                        {...cell.getCellProps()}
                        className="font-poppins text-base font-normal text-[#4d4d4d] py-[15px]"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex flex-row justify-between border-t-[1px] p-3 text-sm">
          <span className="">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions?.length}
            </strong>{" "}
          </span>
          <div className="flex space-x-2">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="bg-[#FFEEDE] text-[#D99149] rounded-md cursor-pointer"
            >
              <KeyboardArrowLeftIcon />
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="bg-[#FFEEDE] text-[#D99149] rounded-md cursor-pointer"
            >
              <KeyboardArrowRightIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default DataTable;
