import React from "react";

const Button = ({ onClick, children, type = "button" }) => {
  return (
    <button
      className="w-full h-[37.023px] flex items-center bg-[#D99149] rounded-[8.048px] text-center text-white font-semibold text-sm justify-center focus:outline-none cursor-pointer"
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
