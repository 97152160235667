import React, { useEffect, useState } from "react";
import boop from "assets/img/boop.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/Common/Input";
import {
  useCheckUserMutation,
  useLoginMutation,
  useSendOtpMutation,
} from "store/features/auth/authApiSlice";
import { setCredentials } from "store/features/auth/authSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth } from "config/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const initialValues = {
  emailOrMobile: "",
  otp: "",
};

const validationSchema = Yup.object({
  emailOrMobile: Yup.string().required("Required email/mobile number"),
  otp: Yup.string().required("OTP required"),
});
const LoginPage = () => {
  const [btnStatus, setBtnStatus] = React.useState("Send OTP");

  const navigate = useNavigate();

  const [
    sendOtp,
    {
      isLoading: isOtpLoading,
      isSuccess: isOtpSuccess,
      isError: isOtpError,
      error: otpError,
    },
  ] = useSendOtpMutation();

  const [
    login,
    {
      data,
      isLoading: isLoginLoading,
      isSuccess: isLoginSuccess,
      isError: isLoginError,
      error,
    },
  ] = useLoginMutation();

  const [
    checkUser,
    {
      isSuccess: checkUserSuccess,
      isError: isCheckUserError,
      error: checkUserError,
    },
  ] = useCheckUserMutation();

  const { token, user } = useSelector((state) => state?.auth);

  const dispatch = useDispatch();

  const [countdown, setCountdown] = useState(0);

  const [phoneOtpLoading, setPhoneOtpLoading] = useState(false);

  useEffect(() => {
    if (isCheckUserError) {
      toast.error(checkUserError?.data?.message || "User not found!");
    }

    if (checkUserSuccess) {
      onSignup();
    }
  }, [isCheckUserError, checkUserError, checkUserSuccess]);

  useEffect(() => {
    if (isOtpSuccess) {
      toast.success("OTP sent successfully!");
    }
    if (isOtpError) {
      toast.error(otpError?.data?.message || "Failed to send OTP");
    }

    if (isLoginSuccess) {
      const { token, admin } = data;
      dispatch(setCredentials({ token, user: admin }));
    }

    if (isLoginError) {
      toast.error(error?.data?.message);
    }
  }, [
    isOtpSuccess,
    isOtpError,
    isLoginSuccess,
    isLoginError,
    error,
    data,
    dispatch,
  ]);

  useEffect(() => {
    if (token) navigate("/dashboard");
  }, [token, navigate]);

  const { values, handleBlur, handleSubmit, handleChange, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: (values) => {
        const { emailOrMobile, otp } = values;
        const payload = {
          otp: Number(otp),
        };
        payload[emailOrMobile.includes("@") ? "email" : "phone_number"] =
          emailOrMobile;
        console.log(values, payload);
        if (values.emailOrMobile.includes("@")) {
          //sendOtp({ email: values.emailOrMobile });
          login(payload);
        } else {
          onOTPVerify(values.otp).then(() => {
            login(payload);
          });
        }
      },
    });

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log(response);
            //onSignup();
          },
          "expired-callback": () => { },
        },
      );
    }
  }

  function onSignup() {
    //setLoading(true);
    setPhoneOtpLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+91" + values.emailOrMobile;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPhoneOtpLoading(false);
        toast.success("OTP sended successfully!");
      })
      .catch((error) => {
        console.log(error);
        setPhoneOtpLoading(false);
      });
  }

  const onOTPVerify = async (otp) => {
    //setLoading(true);
    try {
      const result = await window.confirmationResult.confirm(otp);

      return result;
    } catch (error) {
      toast.error("Failed to send phone OTP!");
    }
  };

  return (
    <div className="w-full h-full flex flex-col items-center p-4">
      <div>
        <img
          src={boop}
          alt="logo"
          className="w-40 h-32 md:w-[200px] md:h-[70px] md:mt-16"
        />
      </div>
      <div id="recaptcha-container"></div>
      <div className="w-full max-w-md">
        <div className="w-full bg-white rounded-md p-6 md:p-8 flex flex-col items-center gap-6 md:gap-8">
          <div className="flex flex-col items-center text-center">
            <div className="text-zinc-700 text-2xl md:text-4xl font-bold">
              Welcome
            </div>
            <div className="text-zinc-400 text-xs mt-1">
              Enter your details to get Login to your account.
            </div>
          </div>
          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <Input
              id={"email_or_mobile"}
              name="emailOrMobile"
              label={"Email/Mobile Number"}
              placeholder="Enter email/mobile number"
              value={values?.emailOrMobile}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.emailOrMobile && touched?.emailOrMobile}
              errorText={errors?.emailOrMobile}
              bordered
            />
            <div className="w-full flex flex-col ">
              <div className="flex items-end">
                <Input
                  id={"otp"}
                  name="otp"
                  label={"OTP"}
                  placeholder="Enter OTP"
                  value={values?.otp}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.otp && touched?.otp}
                  errorText={errors?.otp}
                  bordered
                />
                <button
                  onClick={async () => {
                    setBtnStatus("Sending...");
                    // check if email or mobile
                    if (values.emailOrMobile.includes("@")) {
                      await sendOtp({ email: values.emailOrMobile });
                      setBtnStatus("OTP Sent");
                    } else {
                      await checkUser({ phone_number: values.emailOrMobile });
                      setBtnStatus("OTP Sent");
                    }
                  }}
                  className={`ml-2 md:ml-4 rounded-md px-2 py-1   md:px-4 md:py-2 font-semibold ${countdown > 0
                    ? "bg-gray-500 text-white text-sm"
                    : "bg-faintOrangeBg text-darkOrangeBg text-sm"
                    }`}
                  type="button"
                  disabled={
                    isOtpLoading || !values.emailOrMobile || phoneOtpLoading || btnStatus === "OTP Sent"
                  }
                >
                  {/* {countdown > 0 ? `Resend OTP (${countdown})` : "Resend OTP"} */}
                  {btnStatus}
                </button>
              </div>
            </div>

            <button
              className="w-full h-10 px-3 py-2 bg-darkOrangeBg rounded-md flex justify-center items-center gap-2 md:w-[380px] md:h-[40px] md:px-[9px] md:py-[6px]"
              type="submit"
            >
              <div className="text-white text-md font-bold leading-normal">
                {isLoginLoading ? "Loading.." : "Log In"}
              </div>
            </button>
          </form>
        </div>
      </div>
      <div className="fixed bottom-0 left-0 p-4 gap-3 text-xs md:text-sm text-gray-600 flex flex-row items-start">
        <div className="">Copyright ©2023</div>
        <div>Privacy Policy</div>
      </div>
    </div>
  );
};

export default LoginPage;
