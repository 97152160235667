import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  pagination: {},
};

const petProfileSlice = createSlice({
  name: "pet_profile",
  initialState,
  reducers: {
    setPetProfiles: (state, action) => {
      const { pagination, data } = action.payload;
      state.data = data;
      state.pagination = pagination;
    },
  },
});

export const { setPetProfiles } = petProfileSlice.actions;

export default petProfileSlice.reducer;
