import React, { useState } from 'react';
import "../../../App.css"
function ToggleSwitch({handleChange}) {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    handleChange(isChecked)
  };

  return (
    <div className="w-[52px] h-8 relative">
      <label className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          className="hidden"
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
