import React, { useEffect, useState } from "react";
import { useGetAllAccommodationQuery } from "store/features/service_provider/serviceApiSlice";
import { useGetAllServicesQuery } from "store/features/service_provider/serviceApiSlice";
import { useParams } from "react-router-dom";
import { TableContainer } from "@mui/material";
import DataTable from "components/Common/Table";
import IconButton from "components/Common/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/Common/Button";
import { useNavigate } from "react-router-dom";
function OneServiceProvider() {
  const prams = useParams();
  const navigate = useNavigate();
  const { data: allaccommdata } = useGetAllAccommodationQuery(prams.id);

  const [toggletab, settoggletab] = useState("services");
  const [tabledata, settabledata] = useState();
  const [accommodationdata, setaccomdata] = useState();
  const { data: servicedata, isLoading: serviceloading } =
    useGetAllServicesQuery(prams.id);

  const columns = () => [
    {
      accessor: (d) => d?.service_type,
      Header: "Type",
    },
    {
      accessor: (row) => `${row?.service_name}`,
      Header: "Name",
    },
    {
      accessor: (row) => (
        <div className="flex flex-row gap-3 items-center text-center justify-center">
          {row?.service_pricings?.map((rec) => (
            <span className="bg-orange-100 text-black flex p-1 rounded-lg">
              {rec?.price_modal}
            </span>
          ))}
        </div>
      ),
      Header: "Pricing Models",
    },

    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton
            onClick={() => {
              console.log(d);
              navigate(`/service-provider/${prams.id}/${d?.id}`);
            }}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          // onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];
  const accomcolumns = () => [
    {
      accessor: (d) => d?.accomodation_room_type_name,
      Header: "Type",
    },
    {
      accessor: (row) => `${row?.accomodation_number_of_rooms}`,
      Header: "Number of Rooms",
    },
    {
      accessor: (row) => row?.single_or_group,
      Header: "Single Group",
    },
    {
      accessor: (row) => row?.ac_or_nonAc,
      Header: "Ac/NonAC",
    },
    {
      accessor: (row) => row?.outdoor_or_indoor,
      Header: "Outdoor or Indoor",
    },

    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          {/* <IconButton
            onClick={() => navigate(`/accomadation-management/${d?.id}`)}
          >
            <Visibility style={{ width: "15px", height: "15px" }} />
          </IconButton> */}
          <IconButton
            onClick={() => {
              navigate(`/service-provider/accomm/${prams.id}/${d?.id}`);
            }}
          >
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
          // onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];
  //   console.log(prams, allaccomm, allservices, "params");
  useEffect(() => {
    settabledata(servicedata?.data?.rows);
  }, [servicedata]);
  useEffect(() => {
    setaccomdata(allaccommdata?.data?.rows);
  }, [allaccommdata]);

  // console.log(.data)
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row rounded-lg">
        <button
          className={`pl-5 pr-5 p-2 ${toggletab == "services" ? "bg-orange-600 text-white " : "bg-white text-black"} cursor-pointer  rounded-l-lg `}
          onClick={() => settoggletab("services")}
        >
          Services
        </button>
        <button
          className={`pl-5 pr-5 p-2 ${toggletab == "accommodation" ? "bg-orange-600 text-white" : "bg-white text-black"} cursor-pointer rounded-r-lg `}
          onClick={() => settoggletab("accommodation")}
        >
          Accommodation
        </button>
      </div>
      {toggletab == "services" && (
        <>
          <div className="flex flex-row p-1 justify-between w-full mt-5">
            <label>Service Management / {allaccommdata?.data?.service?.service_name}</label>
            <button
              onClick={() => navigate(`new`, { relative: "path" })}
              className="bg-orange-600 text-white pl-3 pr-3 p-2 rounded-lg text-[1rem]"
            >
              Add Service
            </button>
          </div>
          {serviceloading
            ? "Loading..."
            : tabledata && (
              <div className="flex flex-col space-y-16">
                <TableContainer
                  title="Service details"
                  actionButtons={
                    <Button
                      onClick={() => navigate(`/service-management/new`)}
                    >
                      Add
                    </Button>
                  }
                >
                  <DataTable columns={columns()} data={tabledata} />
                </TableContainer>
              </div>
            )}
        </>
      )}
      {toggletab == "accommodation" && (
        <>
          <div className="flex flex-row p-1 justify-between w-full mt-5">
            <label>Accommodation Management / {allaccommdata?.data?.service?.service_name}</label>
            <button
              onClick={() =>
                navigate(`/service-provider/accomm/${prams.id}/new`)
              }
              className="bg-orange-600 text-white pl-3 pr-3 p-2 rounded-lg text-[1rem]"
            >
              Add Accommodation
            </button>
          </div>
          {serviceloading
            ? "Loading..."
            : tabledata && (
              <div className="flex flex-col space-y-16">
                <TableContainer
                  title="Accommodation details"
                  actionButtons={
                    <Button
                      onClick={() => navigate(`/accomadation-management/new`)}
                    >
                      Add
                    </Button>
                  }
                >
                  <DataTable
                    columns={accomcolumns()}
                    data={accommodationdata}
                  // filterArea={
                  //   <TableFilterContainer>
                  //     <SingleSelect
                  //       showLabel={false}
                  //       className="!bg-white"
                  //       label={"Ac/NonAC"}
                  //       options={[
                  //         { label: "AC", value: "AC" },
                  //         { label: "NonAC", value: "NonAC" },
                  //       ]}
                  //       id={"ac_or_nonAc"}
                  //       name="ac_or_nonAc"
                  //       onChange={handleFilterChange}
                  //     />
                  //     <SingleSelect
                  //       showLabel={false}
                  //       className="!bg-white"
                  //       label={"Outdoor/Indoor"}
                  //       options={[
                  //         { label: "Outdoor", value: "Outdoor" },
                  //         { label: "Indoor", value: "Indoor" },
                  //       ]}
                  //       id={"outdoor_or_indoor"}
                  //       name="outdoor_or_indoor"
                  //       onChange={handleFilterChange}
                  //     />
                  //     <SingleSelect
                  //       showLabel={false}
                  //       className="!bg-white"
                  //       label={"Single/Group"}
                  //       options={[
                  //         { label: "Single", value: "Single" },
                  //         { label: "Group", value: "Group" },
                  //       ]}
                  //       id={"single_or_group"}
                  //       name="single_or_group"
                  //       onChange={handleFilterChange}
                  //     />
                  //   </TableFilterContainer>
                  // }
                  />
                </TableContainer>
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default OneServiceProvider;
