import React from "react";

const PetCard = ({ img, name }) => {
  return (
    <div className="flex flex-col space-y-2">
      {img && (
        <img src={img} alt="" className="h-[100px] w-[100px] rounded-full" />
      )}
      <span className="min-w-[100px] h-[30px] py-[1px] px-[20.9px] text-[12px] font-normal bg-[#FFEEDE] text-[#D99149] rounded-md text-center overflow-x-hidden">
        {name}
      </span>
    </div>
  );
};

export default PetCard;
