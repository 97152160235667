import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sendOtp: builder.mutation({
            query: (data) => ({
                url: "api/v1/admin/auth/send-otp",
                method: "POST",
                body: data,
            }),
        }),
        login: builder.mutation({
            query: (data) => ({
                url: "/api/v1/admin/auth/login",
                method: "POST",
                body: data,
            }),
        }),
        dashboardStatus: builder.query({
            query: () => ({
                url: "/api/v1/pet-service/service-management/stat/dashboard-admin-portal",
                method: "GET",
            }),
        }),
        checkUser: builder.mutation({
            query: (data) => ({
                url: "api/v1/admin/auth/send-otp",
                method: "POST",
                body: data,
            }),
        }),
    }),
});

export const {
    useSendOtpMutation,
    useLoginMutation,
    useDashboardStatusQuery,
    useCheckUserMutation,
} = authApiSlice;