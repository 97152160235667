import { apiSlice } from "../apiSlice";

const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllServices: builder.query({
      query: (queryParams) => ({
        url: `/api/v1/pet-service/service-management/`,

        method: "GET",
      }),
      providesTags: ["Service"],
    }),
    getOneServiceApi: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/pet-service/service-management/${id}`,

        method: "GET",
      }),
      providesTags: ["Service"],
    }),
    DeleteAccommodation: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/pet-service/accomodation/${id}`,

        method: "delete",
      }),
      providesTags: ["Accommodations"],
    }),
    createService: builder.mutation({
      query: (data) => ({
        url: `/api/v1/pet-service/service-management`,
        method: "POST",

        body: data,
      }),
      invalidatesTags: ["Service"],
    }),

    updateService: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/pet-service/service-management/${id}`,
        method: "PUT",

        body: data,
      }),
      invalidatesTags: ["Accommodations"],
    }),
  }),
});

export const {
  useGetAllServicesQuery,
  useDeleteAccommodationMutation,
  useUpdateServiceMutation,
  useGetOneServiceApiQuery,
  useCreateServiceMutation,
} = serviceApiSlice;
