import { Box } from "@mui/material";
import MuiModal from "@mui/material/Modal";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Modal = ({ width = 600, padding = 4, state, handleState, children }) => {
  return (
    <MuiModal
      open={state}
      onClose={handleState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width, p: padding }}>{children}</Box>
    </MuiModal>
  );
};

Modal.propTypes = {
  state: PropTypes.bool.isRequired,
  handleState: PropTypes.func.isRequired,
};

export default Modal;
