import React, { useState } from "react";
import { Tab } from "helper/Constants/DashboardTab";
import DashboardTabCard from "components/Dashboard/DashboardTabCard";
import PetParentTable from "components/Dashboard/PetParentTable";
import PetProfileTable from "components/Dashboard/PetProfileTable";
import DashboardFilter from "components/Dashboard/DashboardFilter";
import SearchFilter from "components/Common/SearchFilter";
import DataTable from "components/Common/Table";
import Altert from "components/Common/Altert";
import IconButton from "components/Common/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useGetParentsQuery } from "store/features/pet_parents/petParentApiSlice";
import { useSelector } from "react-redux";
import { useGetPetProfileQuery } from "store/features/pet_profile/petProfileApiSlice";
import { useDashboardStatusQuery } from "store/features/auth/authApiSlice";
import SingleSelect from "../../components/Common/SingleSelect";

const DashboardHome = () => {
  const navigate = useNavigate();
  const [deleteAltert, setDeleteAlert] = useState({
    state: false,
    id: null,
  });

  const { isLoading: dashboardStatusLoading, data: dashbaordStats } =
    useDashboardStatusQuery();

  const { isLoading } = useGetParentsQuery();

  const { data: petParentData } = useSelector((state) => state?.petParent);

  const { isLoading: petProfileLoading } = useGetPetProfileQuery();

  const { data: petProfileData } = useSelector((state) => state?.petProfile);

  const [filteredData, setFilteredData] = useState(null);
  const [filteredDataPet, setFilteredDataPet] = useState(null);

  const columns = () => [
    {
      accessor: (row) => `${row?.first_name} ${row?.last_name}`,
      Header: "Name",
    },
    {
      accessor: (row) => row?.pets?.length || 0,
      Header: "Pets Associated",
    },
    {
      accessor: (row) => row?.state || "-",
      Header: "Location",
    },
    {
      accessor: "phone",
      Header: "Mobile Number",
    },
    {
      accessor: (row) => 0,
      Header: "No. of Bookings",
    },
    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton onClick={() => navigate(`/pet-parent/${d?.id}`)}>
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton>
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
            onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];

  const petProfileColumns = () => [
    {
      accessor: "name",
      Header: "Pet Name",
    },
    /* {
      accessor: "b",
      Header: "Location",
    }, */
    {
      accessor: "age_year",
      Header: "Age",
    },
    {
      accessor: "weight",
      Header: "Weight",
    },
    {
      accessor: "breed",
      Header: "Bread",
    },
    {
      accessor: (row) => row?.medical_records?.length || 0,
      Header: "Medical Records",
    },
    /* {
      accessor: 'c',
      Header: "Parvo",
    }, */
    {
      accessor: (row) => 0,
      Header: "Donatability",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {/* {Tab.map((data, index) => (
          <DashboardTabCard data={data} key={index} />
        ))} */}
        {dashboardStatusLoading ? (
          "Loading Stats..."
        ) : (
          <>
            <DashboardTabCard data={{ title: "Total Service Providers", count: dashbaordStats?.data?.totalServiceProviders }} />
            <DashboardTabCard
              data={{
                title: "Total Pet Parents",
                count: dashbaordStats?.data?.petParents,
              }}
            />
            <DashboardTabCard
              data={{ title: "Total Pets", count: dashbaordStats?.data?.pets }}
            />
          </>
        )}
      </div>
      <Altert
        title={"Delete"}
        content={"Are you sure to delete this?"}
        state={deleteAltert.state}
        handleState={() =>
          setDeleteAlert((prev) => ({ ...prev, state: !deleteAltert.state }))
        }
        onConfirm={() => console.log("deleted...", deleteAltert)}
      />
      {/* <DashboardSection
        title="Pet Parents"
        setFilteredItems={setFilteredItems}
      /> */}
      <div className="flex flex-col space-y-10">
        <div className="flex flex-col space-y-4">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            pet parents
          </p>
          {isLoading ? (
            "Loading..."
          ) : (
            <div className="font-poppins w-full">
              <DataTable
                columns={columns()}
                data={filteredData ? filteredData : petParentData || []}
                filterArea={
                  <div className="flex flex-col">
                    <span className="text-sm underline ">Filter By</span>
                    <div className="flex flex-row space-x-2">
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Bookings"}
                        options={[
                          { label: "0-5 Bookings", value: "Between 0-5" },
                          { label: "5-10 Bookings", value: "Between 5-10" },
                          { label: "More than 10", value: "Greater 10" },
                        ]}
                        id={"bookings"}
                        name="bookings"
                        onChange={(e) => {
                          const { value } = e?.target;

                          if (value !== "Select Bookings") {
                            const filtered = petParentData.filter((item) => {
                              const bookings = item?.bookings?.length;
                              if (value === "Between 0-5") {
                                return bookings >= 0 && bookings <= 5;
                              } else if (value === "Between 5-10") {
                                return bookings >= 5 && bookings <= 10;
                              } else {
                                return bookings > 10;
                              }
                            });
                            console.log(filtered, "filtered");
                            setFilteredData(filtered);
                          }
                        }}
                      />
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Pets Associated"}
                        options={[
                          { label: "0-5 Pets", value: "Between 0-5" },
                          { label: "5-10 Pets", value: "Between 5-10" },
                          { label: "More than 10", value: "Greater 10" },
                        ]}
                        id={"pets_associated"}
                        name="pets_associated"
                        onChange={(e) => {
                          const { value } = e?.target;

                          if (value !== "Select Pets Associated") {
                            const filtered = petParentData.filter((item) => {
                              const bookings = item?.pets?.length;
                              if (value === "Between 0-5") {
                                return bookings >= 0 && bookings <= 5;
                              } else if (value === "Between 5-10") {
                                return bookings >= 5 && bookings <= 10;
                              } else {
                                return bookings > 10;
                              }
                            });

                            setFilteredData(filtered);
                          } else {
                            setFilteredData(null);
                          }
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>

        <div className="flex flex-col space-y-4">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            updated pet profile
          </p>
          {petProfileLoading ? (
            "Loading..."
          ) : (
            <div className="font-poppins w-full">
              <DataTable
                columns={petProfileColumns()}
                data={filteredDataPet ? filteredDataPet : petProfileData.rows || []}
                filterArea={
                  <div className="flex flex-col">
                    <span className="text-sm underline ">Filter By</span>
                    <div className="flex flex-row space-x-2">
                      <SingleSelect
                        showLabel={false}
                        className="!bg-white"
                        label={"Age"}
                        options={[
                          { label: "0-5 Years", value: "Between 0-5" },
                          { label: "5-10 Years", value: "Between 5-10" },
                          { label: "More than 10", value: "Greater 10" },
                        ]}
                        id={"age"}
                        name="age"
                        onChange={(e) => {
                          const { value } = e?.target;

                          if (value !== "Select Age") {
                            const filtered = petProfileData?.rows?.filter((item) => {
                              const bookings = item?.age;
                              if (value === "Between 0-5") {
                                return bookings >= 0 && bookings <= 5;
                              } else if (value === "Between 5-10") {
                                return bookings >= 5 && bookings <= 10;
                              } else {
                                return bookings > 10;
                              }
                            });

                            setFilteredDataPet(filtered);
                          } else {
                            setFilteredDataPet(null);
                          }
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
      {/* <DataTable columns={columns()} data={[{ name: "harsh verma" }]} />
      <DashboardSection
        title="Pet Profiles"
        setFilteredItems={setFilteredItems}
      /> */}
    </>
  );
};

export default DashboardHome;
