import { apiSlice } from "../apiSlice";

const accommodationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAccommodation: builder.query({
      query: (queryParams) => ({
        url: `/api/v1/pet-service/accomodation/`,

        method: "GET",
      }),
      providesTags: ["Accommodations"],
    }),
    getOneAccommodation: builder.query({
      query: ({ id }) => ({
        url: `/api/v1/pet-service/accomodation/${id}`,

        method: "GET",
      }),
      providesTags: ["Accommodations"],
    }),
    DeleteAccommodation: builder.mutation({
      query: ({ id }) => ({
        url: `/api/v1/pet-service/accomodation/${id}`,

        method: "delete",
      }),
      providesTags: ["Accommodations"],
    }),
    createAccommodation: builder.mutation({
      query: (data) => ({
        url: `/api/v1/pet-service/accomodation`,
        method: "POST",

        body: data,
      }),
      invalidatesTags: ["Accommodations"],
    }),
    filterAppointment: builder.query({
      query: (queryParams) => ({
        url: queryParams
          ? `/api/v1/pet-service/appointment/filter?${queryParams}`
          : `/api/v1/pet-service/appointment/filter`,
        method: "GET",
      }),
      providesTags: ["Appointment"],
    }),
    serviceProvidedAppointment: builder.query({
      query: () => ({
        url: "/api/v1/pet-service/appointment/filter/service-provided",
        method: "GET",
      }),
      providesTags: ["Appointment"],
    }),
    updateAccommodation: builder.mutation({
      query: ({ id, data }) => ({
        url: `/api/v1/pet-service/accomodation/${id}`,
        method: "PUT",

        body: data,
      }),
      invalidatesTags: ["Accommodations"],
    }),
  }),
});

export const {
  useGetAllAccommodationQuery,
  useDeleteAccommodationMutation,
  useUpdateAccommodationMutation,
  useGetOneAccommodationQuery,
  useCreateAccommodationMutation,
  useFilterAppointmentQuery,
  useServiceProvidedAppointmentQuery,
} = accommodationApiSlice;
