import React from "react";

const ViewProfileInfo = ({ title, contents, topActionButton }) => {
  return (
    <div className="flex flex-col p-4 bg-white rounded-sm font-poppins space-y-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col space-y-1">
          <span className="text-[25px] font-normal">{title}</span>
          <div className="h-[1.1px] bg-[#D99149] w-[124px]"></div>
        </div>
        {topActionButton && <div>{topActionButton}</div>}
      </div>
      <div className="flex flex-wrap overflow-x-auto">{contents}</div>
    </div>
  );
};

export default ViewProfileInfo;
