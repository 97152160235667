import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  pagination: {},
};

const petParentSlice = createSlice({
  name: "pet_parent",
  initialState,
  reducers: {
    setPetParents: (state, action) => {
      const { pagination, data } = action.payload;
      state.data = data;
      state.pagination = pagination;
    },
  },
});

export const { setPetParents } = petParentSlice.actions;

export default petParentSlice.reducer;
