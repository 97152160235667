import React from "react";

const Input = ({
  className,
  label,
  name,
  id,
  rows,
  cols,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="font-poppins text-base font-semibold text-[#1C2135]"
      >
        {label}
      </label>
      <textarea
        className={`w-full h-12 px-4 py-2 mt-2 border border-[#F8F8F8] rounded-lg focus:outline-none focus:border-[#F8F8F8] bg-[#F8F8F8] ${className}`}
        id={id}
        name={name}
        rows={rows}
        cols={cols}
        {...props}
      />
    </div>
  );
};

export default Input;
