import React from "react";

const DashboardTabCard = (data) => {
  const { title, count } = data.data;

  return (
    <>
      <div class="flex p-5 items-center h-32 rounded-[26px] bg-gray-50 dark:bg-[#FFE9D4]">
        <div class=" flex flex-col w-full justify-normal items-start gap-2">
          <div>
            <p className="font-inter text-lg font-semibold leading-7 ">
              {title}
            </p>
          </div>
          <div className="flex justify-between items-baseline w-full ">
            <div className="font-inter text-6xl font-semibold leading-[60px] ">
              {count}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardTabCard;
