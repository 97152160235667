import React from "react";

const Input = ({
  className,
  label,
  name,
  id,
  type,
  onChange,
  isError,
  onBlur,
  errorText,
  bordered = false,
  ...props
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="font-poppins text-base font-semibold text-[#1C2135]"
      >
        {label}
      </label>
      <input
        className={`w-full h-10 px-4 py-1 mt-2 border rounded-lg focus:outline-none bg-[#F8F8F8] ${className} ${bordered ? "border-[#1e1e1e] focus:border-[#1e1e1e]" : "border-[#F8F8F8] focus:border-[#F8F8F8]"} disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:border-gray-300`}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        type={type || "text"}
        {...props}
      />
      {isError && (
        <p className="ml-1 font-normal text-sm text-red-500">
          {errorText}{" "}
        </p>
      )}
    </div>
  );
};

export default Input;
