import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./features/apiSlice";
import authSlice from "./features/auth/authSlice";
import petParentSlice from "./features/pet_parents/petParentSlice";
import pet_profileSlice from "./features/pet_profile/pet_profileSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    petParent: petParentSlice,
    petProfile: pet_profileSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default store;
