import React, { useEffect, useState } from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import Footer from "components/Common/Footer";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import profileImg from "assets/img/PetImg.jpg";
import PetProfileIcon from "assets/img/PetProfileIcon.png";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { logout } from "store/features/auth/authSlice";
import { Box, Modal } from "@mui/material";
import config from "config/APIEndpoints";
import toast from "react-hot-toast";
import { ModeComment, Settings } from "@mui/icons-material";
import moment from "moment";

const SideBarLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state?.auth);
  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);
  const menus = [
    { name: "Dashboard", link: "/dashboard", icon: MdOutlineDashboard },
    {
      name: "Business and User",
      icon: AiOutlineUser,
      subMenus: [
        { name: "Pet Parent", link: "/pet-parent" },
        { name: "Pet Profile", link: "/pet-profile" },
        { name: "Service Provider", link: "/service-provider" },
      ],
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const userNavigation = [{ name: "Sign out", href: "#" }];

  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(""); // Track active menu item
  const [activeSubMenu, setActiveSubMenu] = useState(""); // Track active sub-menu item

  const handleMenuClick = (menu) => {
    if (menu.link) {
      setActiveMenu(menu.name); // Set active menu item
      setActiveSubMenu(""); // Reset active sub-menu item
      navigate(menu.link);
    } else if (menu.subMenus) {
      // Toggle the dropdown
      setActiveMenu(activeMenu === menu.name ? "" : menu.name);
      setActiveSubMenu(""); // Reset active sub-menu item
    }
  };

  const handleSubMenuClick = (parentMenu, subMenu) => {
    setActiveMenu(parentMenu); // Set active parent menu item
    setActiveSubMenu(subMenu.name); // Set active sub-menu item
    navigate(subMenu.link);
  };

  const [notificationModalOpen, setnotificationModalOpen] =
    React.useState(false);
  const handleNotificationModalClose = () => setnotificationModalOpen(false);
  const handleNotificationModalOpen = () => setnotificationModalOpen(true);


  const ModalWrapper = ({ open, handleClose, children, p = 4, width = 600 }) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: width,
      bgcolor: "background.paper",
      border: "none",
      boxShadow: 24,
      p: p,
      borderRadius: "20px",
    };

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    );
  };



  const NotificationModal = ({ closeModal }) => {
    const [tab, setTab] = React.useState("all");
    const [notify, setNotify] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      (async () => {
        try {
          const api = config.getEndpoint();
          const user = JSON.parse(localStorage.getItem("boop_admin_user"));
          const res = await fetch(`${api}/api/v1/notifications/admin`, {
            method: "GET",
            headers: {
              "Authorization": `Bearer ${user?.token}`
            }
          });
          const data = await res.json();
          setNotify(data);
        } catch (error) {
          console.log(error);
        }
      })();
    }, []);


    return (
      <>
        <div className="w-full ">
          <div
            className="flex justify-between items-center"
          >
            <span
              className="text-2xl font-poppins font-bold "
            >Notification</span>
            <span
              onClick={async () => {
                try {
                  const api = config.getEndpoint();
                  const user = JSON.parse(localStorage.getItem("boop_admin_user"));
                  const res = await fetch(`${api}/api/v1/notifications/read`, {
                    method: "PUT",
                    headers: {
                      "Authorization": `Bearer ${user?.token}`,
                      "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ id: notify?.map((n) => n?.id) })
                  });
                  await res.json();

                  toast.success("All notifications are marked as read");

                  setNotify(notify.map((not) => ({ ...not, isRead: true })));
                } catch (error) {
                  console.log(error);
                }
              }}
              className="underline cursor-pointer"
            >Mark all as read</span>
          </div>
          {/* Tabs */}
          <div className="flex items-center justify-between">
            <div className="flex justify-start items-center w-max my-4 
             
            ">
              <button
                onClick={() => setTab("all")}
                className={`${tab === "all"
                  ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                  : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                  } font-poppins font-normal text-[14px] px-4 py-1`}
              >
                All
              </button>
              <button
                onClick={() => setTab("new")}
                className={`${tab === "new"
                  ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                  : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                  } font-poppins font-normal text-[14px] px-4 py-1`}
              >
                New
              </button>
              <button
                onClick={() => setTab("previous")}
                className={`${tab === "previous"
                  ? "text-[#FF6B01] border-b-2 border-[#FF6B01]"
                  : "text-[#666C7E]  border-b-2 border-[#8d929e]"
                  } font-poppins font-normal text-[14px] px-4 py-1`}
              >
                Previous
              </button>

            </div>
            <span
              className="flex items-center justify-center cursor-pointer"
            >
              <Settings />
            </span>
          </div>
          <div className="flex-col flex gap-y-2  pb-8 justify-around   space-y-4">
            {notify.length > 0 && notify
              ?.filter((n) => tab === "all" ? n : tab === "new" ? !n?.isRead : n?.isRead)
              ?.map((n) => (
                <>
                  <div
                    onClick={async () => {
                      try {
                        const api = config.getEndpoint();
                        const user = JSON.parse(localStorage.getItem("boop_admin_user"));
                        const res = await fetch(`${api}/api/v1/notifications/read`, {
                          method: "PUT",
                          headers: {
                            "Authorization": `Bearer ${user?.token}`,
                            "Content-Type": "application/json"
                          },
                          body: JSON.stringify({ id: [n?.id] })
                        });
                        await res.json();

                        toast.success("Notification marked as read");

                        setNotify(notify.map((not) => {
                          if (not?.id === n?.id) {
                            return { ...not, isRead: true };
                          }
                          return not;
                        }));
                      } catch (error) {
                        console.log(error);
                      }
                      if (n?.type === "ONBOARD") {
                        navigate("/service-provider");
                      }


                    }}

                    className="flex flex-row items-center justify-between gap-4">
                    <img
                      className="w-auto h-6 self-center"
                      src="/notity.png" alt="notify-logo" />
                    <span className="w-full">
                      <h2 className={`font-bold ${n?.isRead ? `text-[#838383]` : "text-black"} `}>
                        {n?.title}
                      </h2>
                      <h3
                        className="flex items-center justify-start gap-2"
                      >
                        <p
                          className={`w-max ${!n?.isRead && `text-[#FF6B01]`}`}
                        >
                          {moment(n.createdAt).fromNow()}
                        </p>
                        <p>
                          | {n?.message}
                        </p>
                      </h3>
                    </span>
                    {!n?.isRead && <span
                      className="self-end "

                    >
                      <div className="w-6 h-6 cursor-pointer rounded-full bg-[#FF6B01]"></div>

                    </span>}
                  </div>
                </>
              ))}
          </div>
        </div>
      </>
    );
  };



  return (
    <section className="flex">
      <div
        className={`bg-white min-h-screen ${open ? "w-72" : "w-16"} text-[#4F5B67] px-4`}
      >
        <div className="py-6 flex justify-end">
          <HiMenuAlt3
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus?.map((menu, i) => (
            <div key={i}>
              <div
                onClick={() => handleMenuClick(menu)}
                className={`group flex items-center text-sm text-[#8F8F91] gap-3.5 font-medium p-2 rounded-md cursor-pointer ${activeMenu === menu.name &&
                  menu.link &&
                  "bg-faintOrangeBg text-darkOrangeBg"
                  } ${!menu.link && "hover:bg-darkOrangeBg hover:text-white"}`}
              >
                <div>
                  <menu.icon className="w-[20px] h-[20px]" />
                </div>
                <h2
                  className={`whitespace-pre font-poppins text-[17px] font-medium ${!open && "overflow-hidden"}`}
                >
                  {menu?.name}
                </h2>
                {menu.subMenus && open && (
                  <ArrowDropDownIcon
                    className={`transition-transform ${activeMenu === menu.name && "rotate-180"}`}
                  />
                )}
              </div>
              {menu.subMenus && activeMenu === menu.name && open && (
                <div>
                  {menu.subMenus.map((subMenu, j) => (
                    <div
                      key={j}
                      onClick={() => handleSubMenuClick(menu.name, subMenu)}
                      className={`flex items-center text-sm text-[#8F8F91] gap-3.5 font-medium p-2 rounded-md cursor-pointer ${activeSubMenu === subMenu.name &&
                        "bg-faintOrangeBg text-darkOrangeBg"
                        }`}
                    >
                      <span
                        className={`text-2xl ${activeSubMenu === subMenu.name ? "text-darkOrangeBg" : "text-transparent"}`}
                      >
                        •
                      </span>
                      <span className="whitespace-pre font-poppins text-[17px] font-normal">
                        {subMenu.name}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full text-xl text-gray-900 bg-[#F6F6F6] font-semibold">
        <div className="flex justify-between items-center bg-white px-8 py-4 gap-4">
          <img src={PetProfileIcon} className="h-10 w-36" alt="logo" />
          <div className="flex justify-normal items-center gap-4">
            <div className="w-[56px] h-[56px] bg-[#F6F6F6] flex justify-center cursor-pointer items-center rounded-xl">
              <NotificationsNoneOutlinedIcon onClick={handleNotificationModalOpen} className="text-darkOrangeBg" />
            </div>
            <Menu as="div" className="relative ml-3">
              <div>
                <MenuButton className="relative flex max-w-xs items-center rounded-xl bg-white text-sm focus:outline-none focus:ring-2 focus:ring-darkOrangeBg focus:ring-offset-2">
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="w-[56px] h-[56px] rounded-xl"
                    src={profileImg}
                    alt=""
                  />
                </MenuButton>
              </div>
              <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {userNavigation.map((item) => (
                  <MenuItem key={item.name}>
                    {({ focus }) => (
                      <button
                        onClick={() => {
                          dispatch(logout());
                          navigate("/login");
                        }}
                        className={classNames(
                          focus ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700 w-full text-left font-normal",
                        )}
                      >
                        {item.name}
                      </button>
                    )}
                  </MenuItem>
                ))}
              </MenuItems>
            </Menu>
          </div>
        </div>
        <ModalWrapper
          p={2}
          width={600}
          open={notificationModalOpen}
          handleClose={() => setnotificationModalOpen(false)}
        >
          <NotificationModal closeModal={() => setnotificationModalOpen(false)} />
        </ModalWrapper>
        <div>
          <div className="p-8">
            <Outlet />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </section>
  );
};

export default SideBarLayout;
