import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const devFirebaseConfig = {
  apiKey: "AIzaSyBhDzggaYN16IE9QcgRSL_1YCvNFwd2F9w",
  authDomain: "boop-admin-dev.firebaseapp.com",
  projectId: "boop-admin-dev",
  storageBucket: "boop-admin-dev.appspot.com",
  messagingSenderId: "40057975766",
  appId: "1:40057975766:web:964ef13fc41f3c532d6a77",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyD7-TY93MEt7PwUtE61XWq3ATG4VA-2GaE",
  authDomain: "boop-admin-prod.firebaseapp.com",
  projectId: "boop-admin-prod",
  storageBucket: "boop-admin-prod.appspot.com",
  messagingSenderId: "597111575643",
  appId: "1:597111575643:web:d62f1b6ed5b80684735b7e",
};
const configurations =
  process.env.REACT_APP_ENV === "production"
    ? prodFirebaseConfig
    : devFirebaseConfig;
const app = initializeApp(configurations);

export const auth = getAuth(app);
