import { apiSlice } from "../apiSlice";
import { setPetParents } from "./petParentSlice";

export const petParentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createParent: builder.mutation({
      query: (data) => ({
        url: "/api/v1/admin/pet-parent",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PetParent"],
    }),
    getParents: builder.query({
      query: () => ({
        url: "/api/v1/admin/pet-parent",
        method: "GET",
      }),
      providesTags: ["PetParent"],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          const { data } = response;
          dispatch(
            setPetParents({
              data: data?.data?.rows,
              pagination: { count: data?.data?.count },
            }),
          );
        });
      },
    }),
    deleteParent: builder.mutation({
      query: (id) => ({
        url: `/api/v1/admin/pet-parent/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PetParent"],
    }),
    getOneParent: builder.query({
      query: (queryParams) => ({
        url: `/api/v1/admin/pet-parent/one?${queryParams}`,
        method: "GET",
      }),
      providesTags: ["PetParent"],
    }),
    addPetAndParent: builder.mutation({
      query: (data) => ({
        url: "/api/v1/petparent/pets/add/parent",
        method: "PUT",
        body: data,
      }),
    }),
    updateParent: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/api/v1/admin/pet-parent/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["PetParent"],
    }),
    getAllpets: builder.query({
      query: () => ({
        url: `/api/v1/petparent/pets`,
        method: "GET",
      }),
      invalidatesTags: ["PetParent"],
    }),
  }),
});

export const {
  useCreateParentMutation,
  useGetParentsQuery,
  useDeleteParentMutation,
  useGetOneParentQuery,
  useAddPetAndParentMutation,
  useUpdateParentMutation,
  useGetAllpetsQuery,
} = petParentApiSlice;