import React from "react";
import config from "config/APIEndpoints";
import { useNavigate } from "react-router-dom";
import { useGetAllServiceProviderQuery } from "store/features/service_provider/serviceApiSlice";

import DataTable from "components/Common/Table";
function ServiceProvider() {
  const columns = () => [
    {
      accessor: (row) => (
        <span
          className="hover:text-orange-500 cursor-pointer transition ease-in-out"
          onClick={() => navigate(`/service-provider/${row?.id}`)}
        >
          {row?.service_name}
        </span>
      ),
      Header: "Service Name",
    },
    {
      accessor: "email",
      Header: "Email",
    },
    {
      Header: "Phone Number",
      accessor: "service_phone_number",
    },
    {
      Header: "Address",
      accessor: "location_address",
    },
    {
      accessor: "state",
      Header: "State",
    },
    {
      accessor: "city",
      Header: "City",
    },
  ];
  const navigate = useNavigate();
  const { data, isLoading } = useGetAllServiceProviderQuery();
  console.log(data);
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-between">
        <p className="font-poppins font-semibold text-[28px] capitalize">
          Service Providers
        </p>
        <button
          onClick={() => {
            window.location = config.getCustomerEndpoint();
          }}
          className="bg-darkOrangeBg w-fit px-6 py-5 h-[25px] cursor-pointer flex text-sm justify-center font-semibold items-center text-white rounded-md"
        >
          Add Servicer Provider
        </button>
      </div>
      {data?.services && (
        <div className="font-poppins w-full">
          <DataTable
            columns={columns()}
            data={data?.services ? data?.services : null}
          // filterArea={
          //   <div className="flex flex-col">
          //     <span className="text-sm underline ">Filter By</span>
          //     <div className="flex flex-row space-x-2">

          //       <SingleSelect
          //         showLabel={false}
          //         className="!bg-white"
          //         label={"Age"}
          //         options={[
          //           { label: "0-5 Years", value: "Between 0-5" },
          //           { label: "5-10 Years", value: "Between 5-10" },
          //           { label: "More than 10", value: "Greater 10" },
          //         ]}
          //         id={"age"}
          //         name="age"
          //         onChange={(e) => {
          //           const { value } = e?.target;

          //           if (value !== "Select Age") {
          //             const filtered = petProfileData.filter((item) => {
          //               const bookings = item?.age;
          //               if (value === "Between 0-5") {
          //                 return bookings >= 0 && bookings <= 5;
          //               } else if (value === "Between 5-10") {
          //                 return bookings >= 5 && bookings <= 10;
          //               } else {
          //                 return bookings > 10;
          //               }
          //             });

          //             setFilteredData(filtered);
          //           } else {
          //             setFilteredData(null);
          //           }
          //         }}
          //       />

          //     </div>
          //   </div>
          // }
          />
        </div>
      )}
    </div>
  );
}

export default ServiceProvider;
