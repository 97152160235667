import React, { useEffect, useState } from "react";
import Modal from "components/Common/Modal";
import * as Yup from "yup";
import { useFormik } from "formik";
import Input from "../../components/Common/Input";
import Textarea from "../../components/Common/Textarea";
import Button from "../../components/Common/Button";
import Altert from "components/Common/Altert";
import {
  useCreateParentMutation,
  useDeleteParentMutation,
  useGetParentsQuery,
} from "store/features/pet_parents/petParentApiSlice";
import {
  useCreatePetMutation,
  useGetPetProfileQuery,
} from "store/features/pet_profile/petProfileApiSlice";
import toast from "react-hot-toast";
import DataTable from "../../components/Common/Table";
import { useSelector } from "react-redux";
import SingleSelect from "../../components/Common/SingleSelect";
import GroupRaio from "../../components/Common/RadioGroup";
import { useNavigate } from "react-router-dom";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const initialState = {
  state: false,
  edit_id: null,
  data: {
    name: "",
    type: "",
    breed: "",
    gender: "",
    age: "",
    weight: "",
    dob: "",
    neutered_or_spayed: false,
    parent_id: null,
    documents: null,
  },
};

const validationSchema = {
  name: Yup.string().required("Name is required"),
  type: Yup.string().optional(),
  breed: Yup.string().required("Breed is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.number().required("Age is required"),
  weight: Yup.string().optional(),
  dob: Yup.string().optional(),
  neutered_or_spayed: Yup.boolean().optional().default(false),
};

const PetProfile = () => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(initialState);
  const [deleteAltert, setDeleteAlert] = useState({
    state: false,
    id: null,
  });

  const [
    createPet,
    {
      isLoading: createPetLoading,
      isSuccess: petCreateSuccess,
      isError: petCreateError,
    },
  ] = useCreatePetMutation();

  const { isLoading } = useGetPetProfileQuery();

  const [importModal, setImportModal] = useState(false);

  const { data: petProfileData } = useSelector((state) => state?.petProfile);

  const [filteredData, setFilteredData] = useState(null);
  const [
    deleteParent,
    { isSuccess: deleteSuccess, isError: isDeleteError, error: deleteErrors },
  ] = useDeleteParentMutation();

  useEffect(() => {
    if (petCreateSuccess) {
      toast.success("Pet created successfully!");
      setModalState(initialState);
    }

    if (petCreateError) {
      toast.error("Failed to create Pet!");
    }
  }, [petCreateSuccess, petCreateError]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Pet deleted successfully!");
      setDeleteAlert({ id: null, state: false });
    }

    if (isDeleteError) {
      toast.error("Failed to delete Pet!");
    }
  }, [deleteSuccess, isDeleteError, deleteErrors]);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    touched,
    values,
    setValues,
  } = useFormik({
    initialValues: initialState.data,
    enableReinitialize: true,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (values, action) => {
      const formdata = new FormData();
      formdata.append("name", values?.name);
      formdata.append("type", values?.type);
      formdata.append("breed", values?.breed);
      formdata.append("gender", values?.gender);
      formdata.append("weight", values?.weight);
      formdata.append("dob", values?.dob);
      formdata.append("neutered_or_spayed", values?.neutered_or_spayed);
      formdata.append("age_year", values?.age);
      formdata.append("parent_id", values?.parent_id);
      console.log({ formdata });
      await createPet(formdata);
      if (petCreateSuccess) action.resetForm();
    },
  });

  const petModal = () => {
    const { edit_id, data, state } = modalState;

    return (
      <Modal
        state={state}
        width={700}
        handleState={() =>
          setModalState((prev) => ({ ...prev, state: !state }))
        }
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {edit_id ? "Update " : "Add "}pet
          </p>

          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="grid grid-cols-3">
              <div className="col-span-2">
                <Input
                  id={"name"}
                  name="name"
                  label={"Name"}
                  placeholder="Enter name"
                  value={values?.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isError={errors?.name && touched?.name}
                  errorText={errors?.name}
                />
                <div className="flex flex-row space-x-2">
                  <Input
                    id={"type"}
                    name="type"
                    label={"Type"}
                    className={"mt-2"}
                    placeholder="Enter type"
                    value={values?.type}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={errors?.type && touched?.type}
                    errorText={errors?.type}
                  />

                  <Input
                    id={"breed"}
                    name="breed"
                    label={"Breed"}
                    placeholder="Enter breed"
                    className={"mt-2"}
                    value={values?.breed}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isError={errors?.breed && touched?.breed}
                    errorText={errors?.breed}
                  />
                </div>

                <div className="flex flex-col mt-2">
                  <label
                    for="countries"
                    class="font-poppins text-base font-semibold text-[#1C2135]"
                  >
                    {"Neutered Or Spayed"}
                  </label>

                  <div className="flex flex-row p-1 space-x-1 rounded-md bg-[#F8F8F8]">
                    <div
                      className={`w-full p-1 text-center rounded-md text-black  cursor-pointer ${values.neutered_or_spayed && " bg-darkOrangeBg text-white"}`}
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          neutered_or_spayed: true,
                        }))
                      }
                    >
                      Yes
                    </div>
                    <div
                      className={`w-full p-1 text-center rounded-md text-black  cursor-pointer ${!values.neutered_or_spayed && " bg-darkOrangeBg text-white"}`}
                      onClick={() =>
                        setValues((prev) => ({
                          ...prev,
                          neutered_or_spayed: false,
                        }))
                      }
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center">
                  <label className="h-[145px] w-[145px] flex flex-col items-center justify-center space-y-4 rounded-lg border border-darkOrangeBg hover:shadow-lg transition ease-in-out">
                    <input type="file" className="hidden" />
                    <AddPhotoAlternateOutlinedIcon
                      style={{ height: 40, width: 40, color: "#D99149" }}
                    />
                    <p className="text-[13px] font-medium">Add Picture</p>
                  </label>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-2 ">
              <SingleSelect
                label={"Gender"}
                options={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                id={"gender"}
                name="gender"
                value={values?.gender}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.gender && touched?.gender}
                errorText={errors?.gender}
              />

              <Input
                id={"weight"}
                name="weight"
                label={"Weight (kg)"}
                placeholder="Weight"
                value={values?.weight}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.weight && touched?.weight}
                errorText={errors?.weight}
              />

              <Input
                id={"age"}
                name="age"
                label={"Age (Yrs)"}
                placeholder="Age"
                value={values?.age}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.age && touched?.age}
                errorText={errors?.age}
              />

              <Input
                id={"dob"}
                name="dob"
                label={"DOB"}
                placeholder="DOB"
                type="date"
                value={values?.dob}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.dob && touched?.dob}
                errorText={errors?.dob}
              />
            </div>

            {/* <Input
              id={"documents"}
              name="documents"
              label={"Add Pet Related Documents"}
              placeholder="Add Pet Related Documents"
              type="file"
              value={values?.documents}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.documents && touched?.documents}
              errorText={errors?.documents}
            /> */}

            <Input
              id={"parent_id"}
              name="parent_id"
              label={"Link a Parent"}
              placeholder="Link a Parent"
              value={values?.parent_id}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.parent_id && touched?.parent_id}
              errorText={errors?.parent_id}
            />

            <div className="flex flex-col space-y-2">
              <Button type="submit">
                {createPetLoading ? "Loading..." : edit_id ? "Update" : "Add"}
              </Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() =>
                  setModalState((prev) => ({ ...prev, state: false }))
                }
              >
                Go Back
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  const columns = () => [
    {
      accessor: (row) => (
        <span
          className="hover:text-orange-500 cursor-pointer transition ease-in-out"
          onClick={() => navigate(`/pet-profile/${row?.id}`)}
        >
          {row?.name}
        </span>
      ),
      Header: "Pet Name",
    },
    /* {
      accessor: "b",
      Header: "Location",
    }, */
    {
      accessor: "age_year",
      Header: "Age (yrs)",
    },
    {
      accessor: "weight",
      Header: "Weight",
    },
    {
      accessor: "breed",
      Header: "Bread",
    },
    {
      accessor: (row) => row?.medical_records?.length || 0,
      Header: "Medical Records",
    },
    /* {
      accessor: 'c',
      Header: "Parvo",
    }, */
    {
      accessor: (row) => 0,
      Header: "Donatability",
    },
  ];

  const importData = () => {
    return (
      <Modal
        state={importModal}
        width={500}
        handleState={() => setImportModal((prev) => !prev)}
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            Bulk Upload
          </p>
        </div>
        <p className="text-sm font-light text-gray-400 mb-3">
          Download excel format to import data.{" "}
          <a
            className="text-darkOrangeBg text-sm"
            href="/"
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>
        </p>
        <form className="flex flex-col space-y-3">
          <Input
            name="bulk_upload"
            className="w-full"
            type="file"
            label={"Select File"}
          />

          <Button type="submit">Import</Button>
        </form>
      </Modal>
    );
  };

  return (
    <>
      <div class="flex flex-col w-full space-y-4">
        <Altert
          title={"Delete"}
          content={"Are you sure to delete this?"}
          state={deleteAltert.state}
          handleState={() =>
            setDeleteAlert((prev) => ({ ...prev, state: !deleteAltert.state }))
          }
          onConfirm={() => deleteParent(deleteAltert?.id)}
        />
        {petModal()}
        {importData()}
        <div className="flex justify-between items-center space-x-2">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            pet profile
          </p>
          <div className="flex flex-row space-x-2">
            <p
              className="bg-darkOrangeBg w-fit px-6 py-5 h-[25px] cursor-pointer flex text-sm justify-center font-semibold items-center text-white rounded-md"
              onClick={() =>
                setModalState((prev) => ({ ...prev, state: true }))
              }
            >
              Add New Pet
            </p>
            <p
              className="bg-darkOrangeBg w-fit px-6 py-5 h-[25px] cursor-pointer flex text-sm justify-center font-semibold items-center text-white rounded-md"
              onClick={() => setImportModal(true)}
            >
              Bulk Upload
            </p>
          </div>
        </div>
        {isLoading ? (
          "Loading..."
        ) : (
          <div className="font-poppins w-full">
            <DataTable
              columns={columns()}
              data={filteredData ? filteredData : petProfileData?.rows}
              filterArea={
                <div className="flex flex-col">
                  <span className="text-sm underline ">Filter By</span>
                  <div className="flex flex-row space-x-2">
                    {/* <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Location"}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      id={"Location"}
                      name="location"
                    /> */}
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Age"}
                      options={[
                        { label: "0-5 Years", value: "Between 0-5" },
                        { label: "5-10 Years", value: "Between 5-10" },
                        { label: "More than 10", value: "Greater 10" },
                      ]}
                      id={"age"}
                      name="age"
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (value !== "Select Age") {
                          const filtered = petProfileData.rows.filter((item) => {
                            const bookings = item?.age;
                            if (value === "Between 0-5") {
                              return bookings >= 0 && bookings <= 5;
                            } else if (value === "Between 5-10") {
                              return bookings >= 5 && bookings <= 10;
                            } else {
                              return bookings > 10;
                            }
                          });

                          setFilteredData(filtered);
                        } else {
                          setFilteredData(null);
                        }
                      }}
                    />
                    {/* <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Pet Type"}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      id={"pet_type"}
                      name="pet_type"
                    /> */}
                    {/* <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Parvo Type"}
                      options={[
                        { label: "Male", value: "male" },
                        { label: "Female", value: "female" },
                      ]}
                      id={"pravo_type"}
                      name={"pravo_type"}
                    /> */}
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PetProfile;
