import React from "react";

const Input = ({
  className,
  label,
  name,
  id,
  type,
  onChange,
  isError,
  onBlur,
  errorText,
  disabled,
  required,
  bordered = false,
  ...props
}) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="font-poppins  text-[#1C2135] text-[1rem] font-bold"
        >
          {label}
        </label>
      )}
      {required && <span className="text-red-500">*</span>}
      <input
        className={`w-full h-10 px-4 py-1 mt-2 border rounded-lg focus:outline-none bg-gray-100 ${className} ${bordered ? "border-[#BDC1CA] focus:border-[#BDC1CA]" : "border-[#F8F8F8] focus:border-[#F8F8F8]"}
         disabled:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50 disabled:border-gray-300 ${isError ? "border-red-500" : ""} text-[1rem]`}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        type={type || "text"}
        {...props}
      />
      {isError && (
        <p className="ml-1 font-normal text-sm text-red-500">{errorText} </p>
      )}
    </div>
  );
};

export default Input;
