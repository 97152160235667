import React, { useEffect, useState } from "react";
import Modal from "components/Common/Modal";
import * as Yup from "yup";
import { Formik, useFormik } from "formik";
import Input from "../../components/Common/Input";
import Textarea from "../../components/Common/Textarea";
import Button from "../../components/Common/Button";
import Altert from "components/Common/Altert";
import {
  useCreateParentMutation,
  useDeleteParentMutation,
  useGetParentsQuery,
  useUpdateParentMutation,
} from "store/features/pet_parents/petParentApiSlice";
import toast from "react-hot-toast";
import DataTable from "../../components/Common/Table";
import IconButton from "../../components/Common/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SingleSelect from "../../components/Common/SingleSelect";

const initialState = {
  state: false,
  edit_id: null,
  data: {
    first_name: "",
    last_name: "",
    email: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    phone_emergency: "",
  },
};

const validationSchema = {
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().optional(),
  email: Yup.string().email().required("Email is required"),
  address_1: Yup.string().required("Address 1 is required"),
  address_2: Yup.string().optional(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string().required("Pincode is required"),
  phone: Yup.string().required("Phone is required"),
  phone_emergency: Yup.string().optional(),
};

const PetParents = () => {
  const navigate = useNavigate();
  const [modalState, setModalState] = useState(initialState);
  const [deleteAltert, setDeleteAlert] = useState({
    state: false,
    id: null,
  });

  const [importModal, setImportModal] = useState(false);

  const [
    createParent,
    {
      isLoading: createParentLoading,
      isSuccess: parentCreateSuccess,
      isError: parentCreateError,
    },
  ] = useCreateParentMutation();

  const { isLoading } = useGetParentsQuery();

  const [
    updateParent,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: updateError,
    },
  ] = useUpdateParentMutation();

  const { data: petParentData } = useSelector((state) => state?.petParent);

  const [
    deleteParent,
    { isSuccess: deleteSuccess, isError: isDeleteError, error: deleteErrors },
  ] = useDeleteParentMutation();

  useEffect(() => {
    if (parentCreateSuccess) {
      toast.success("Parent created successfully!");
      setModalState(initialState);
    }

    if (parentCreateError) {
      toast.error("Failed to create parent!");
    }
  }, [parentCreateSuccess, parentCreateError]);

  useEffect(() => {
    if (deleteSuccess) {
      toast.success("Parent deleted successfully!");
      setDeleteAlert({ id: null, state: false });
    }

    if (isDeleteError) {
      toast.error("Failed to delete parent!");
    }
  }, [deleteSuccess, isDeleteError, deleteErrors]);

  useEffect(() => {
    if (updateSuccess) {
      toast.success("Parent updated successfully!");
      setModalState(initialState);
    }

    if (updateError) {
      toast.error("Failed to update parent!");
    }
  }, [updateSuccess, updateError]);

  const [filteredData, setFilteredData] = useState(null);

  const { handleBlur, handleSubmit, handleChange, errors, touched, values } =
    useFormik({
      initialValues: modalState.data,
      enableReinitialize: true,
      validationSchema: Yup.object(validationSchema),
      onSubmit: async (values, action) => {
        if (modalState.edit_id) {
          // update
          await updateParent({ id: modalState.edit_id, ...values });
        } else {
          // create
          await createParent(values);
        }
        action.resetForm();
      },
    });

  const parentModal = () => {
    const { edit_id, state } = modalState;

    return (
      <Modal state={state} handleState={() => setModalState(initialState)}>
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            {edit_id ? "Update " : "Add "}pet parent
          </p>

          <form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
            <div className="flex flex-row space-x-2">
              <Input
                id={"first_name"}
                name="first_name"
                label={"First Name"}
                placeholder="Enter first name"
                value={values?.first_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.first_name && touched?.first_name}
                errorText={errors?.first_name}
              />

              <Input
                id={"last_name"}
                name="last_name"
                label={"Last Name"}
                placeholder="Enter last name"
                value={values?.last_name}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.last_name && touched?.last_name}
                errorText={errors?.last_name}
              />
            </div>

            <Input
              id={"email"}
              name="email"
              label={"Email Address"}
              placeholder="Enter email address"
              type="email"
              value={values?.email}
              onBlur={handleBlur}
              onChange={handleChange}
              isError={errors?.email && touched?.email}
              errorText={errors?.email}
            />

            <div className="flex flex-row space-x-2">
              <Textarea
                id={"address_1"}
                name="address_1"
                label={"Address 1"}
                placeholder="Your address comes here"
                value={values?.address_1}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.address_1 && touched?.address_1}
                errorText={errors?.address_1}
              />

              <Textarea
                id={"address_2"}
                name="address_2"
                label={"Address 2"}
                placeholder="Your address comes here"
                value={values?.address_2}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.address_2 && touched?.address_2}
                errorText={errors?.address_2}
              />
            </div>

            <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
              <Input
                id={"city"}
                name="city"
                label={"City"}
                placeholder="City"
                value={values?.city}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.city && touched?.city}
                errorText={errors?.city}
              />

              <Input
                id={"state"}
                name="state"
                label={"State"}
                placeholder="State"
                value={values?.state}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.state && touched?.state}
                errorText={errors?.state}
              />

              <Input
                id={"pincode"}
                name="pincode"
                label={"Pin Code"}
                placeholder="Zip Code"
                value={values?.pincode}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.pincode && touched?.pincode}
                errorText={errors?.pincode}
              />
            </div>

            <div className="flex flex-row space-x-2">
              <Input
                id={"phone"}
                name="phone"
                label={"Mobile Number"}
                placeholder="Enter mobile number"
                value={values?.mobile_number}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.phone && touched?.phone}
                errorText={errors?.phone}
              />

              <Input
                id={"phone_emergency"}
                name="phone_emergency"
                label={"Emergency Number"}
                placeholder="Enter emergency number"
                value={values?.phone_emergency}
                onBlur={handleBlur}
                onChange={handleChange}
                isError={errors?.phone_emergency && touched?.phone_emergency}
                errorText={errors?.phone_emergency}
              />
            </div>

            <div className="flex flex-col space-y-2">
              <Button type="submit">
                {createParentLoading || updateLoading
                  ? "Loading..."
                  : edit_id
                    ? "Update"
                    : "Add"}
              </Button>
              <div
                className="text-center underline text-gray-400 text-sm cursor-pointer"
                onClick={() =>
                  setModalState((prev) => ({ ...prev, state: false }))
                }
              >
                Go Back
              </div>
            </div>
          </form>
        </div>
      </Modal>
    );
  };

  const handleEdit = (id) => {
    const findData = petParentData.find((item) => item?.id === id);

    if (findData) {
      setModalState({
        state: true,
        edit_id: id,
        data: {
          first_name: findData?.first_name,
          last_name: findData?.last_name,
          email: findData?.email,
          address_1: findData?.address_1,
          address_2: findData?.address_2,
          city: findData?.city,
          state: findData?.state,
          pincode: findData?.pincode,
          phone: findData?.phone,
          phone_emergency: findData?.phone_emergency,
        },
      });
    }
  };

  const columns = () => [
    // {
    //   accessor: (d) => d?.id,
    //   Header: "Parent ID",
    // },
    {
      accessor: (row) => `${row?.first_name} ${row?.last_name}`,
      Header: "Name",
    },
    {
      accessor: (row) => row?.pets?.length || 0,
      Header: "Pets Associated",
    },
    {
      accessor: (row) => row?.state || "-",
      Header: "Location",
    },
    {
      accessor: "phone",
      Header: "Mobile Number",
    },
    {
      accessor: (row) => 0,
      Header: "No. of Bookings",
    },
    {
      accessor: (d) => (
        <div className="flex flex-row gap-2 items-center justify-center">
          <IconButton onClick={() => navigate(`/pet-parent/${d?.id}`)}>
            <VisibilityIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton onClick={() => handleEdit(d?.id)}>
            <BorderColorIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
          <IconButton
            onClick={() => setDeleteAlert({ id: d?.id, state: true })}
          >
            <DeleteIcon style={{ width: "15px", height: "15px" }} />
          </IconButton>
        </div>
      ),
      Header: "Action",
    },
  ];

  const importData = () => {
    return (
      <Modal
        state={importModal}
        width={500}
        handleState={() => setImportModal((prev) => !prev)}
      >
        <div className="flex flex-col space-y-5">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            Bulk Upload
          </p>
        </div>
        <p className="text-sm font-light text-gray-400 mb-3">
          Download excel format to import data.{" "}
          <a
            className="text-darkOrangeBg text-sm"
            href="/Petbulkuploadformat.xlsx"
            download={true}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>
        </p>
        <Formik
          initialValues={{ file: "" }}
          onSubmit={(values) => { }}
        >
          {({ setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col space-y-3">
              <Input
                name="file"
                onChange={(e) => {
                  setFieldValue("file", e?.target?.files[0]);
                }}
                className="w-full"
                type="file"
                label={"Select File"}
              />

              <Button type="submit">Import</Button>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  return (
    <>
      <div class="flex flex-col w-full space-y-4">
        <Altert
          title={"Delete"}
          content={"Are you sure to delete this?"}
          state={deleteAltert.state}
          handleState={() =>
            setDeleteAlert((prev) => ({ ...prev, state: !deleteAltert.state }))
          }
          onConfirm={() => deleteParent(deleteAltert?.id)}
        />
        {parentModal()}
        {importData()}
        <div className="flex justify-between items-center space-x-2">
          <p className="font-poppins font-semibold text-[28px] capitalize">
            pet parents
          </p>
          <div className="flex flex-row space-x-2">
            <p
              className="bg-darkOrangeBg w-fit px-6 py-5 h-[25px] cursor-pointer flex text-sm justify-center font-semibold items-center text-white rounded-md"
              onClick={() =>
                setModalState((prev) => ({ ...prev, state: true }))
              }
            >
              Add New Parent
            </p>
            <p
              className="bg-darkOrangeBg w-fit px-6 py-5 h-[25px] cursor-pointer flex text-sm justify-center font-semibold items-center text-white rounded-md"
              onClick={() => setImportModal(true)}
            >
              Bulk Upload
            </p>
          </div>
        </div>
        {isLoading ? (
          "Loading..."
        ) : (
          <div className="font-poppins w-full">
            <DataTable
              columns={columns()}
              data={filteredData ? filteredData : petParentData}
              filterArea={
                <div className="flex flex-col">
                  <span className="text-sm underline ">Filter By</span>
                  <div className="flex flex-row space-x-2">
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Bookings"}
                      options={[
                        { label: "0-5 Bookings", value: "Between 0-5" },
                        { label: "5-10 Bookings", value: "Between 5-10" },
                        { label: "More than 10", value: "Greater 10" },
                      ]}
                      id={"bookings"}
                      name="bookings"
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (value !== "Select Bookings") {
                          const filtered = petParentData.filter((item) => {
                            const bookings = item?.bookings?.length;
                            if (value === "Between 0-5") {
                              return bookings >= 0 && bookings <= 5;
                            } else if (value === "Between 5-10") {
                              return bookings >= 5 && bookings <= 10;
                            } else {
                              return bookings > 10;
                            }
                          });

                          setFilteredData(filtered);
                        }
                      }}
                    />
                    <SingleSelect
                      showLabel={false}
                      className="!bg-white"
                      label={"Pets Associated"}
                      options={[
                        { label: "0-5 Pets", value: "Between 0-5" },
                        { label: "5-10 Pets", value: "Between 5-10" },
                        { label: "More than 10", value: "Greater 10" },
                      ]}
                      id={"pets_associated"}
                      name="pets_associated"
                      onChange={(e) => {
                        const { value } = e?.target;

                        if (value !== "Select Pets Associated") {
                          const filtered = petParentData.filter((item) => {
                            const bookings = item?.pets?.length;
                            if (value === "Between 0-5") {
                              return bookings >= 0 && bookings <= 5;
                            } else if (value === "Between 5-10") {
                              return bookings >= 5 && bookings <= 10;
                            } else {
                              return bookings > 10;
                            }
                          });

                          setFilteredData(filtered);
                        } else {
                          setFilteredData(null);
                        }
                      }}
                    />
                  </div>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PetParents;
