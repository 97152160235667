import React from "react";
import { TiAttachment } from "react-icons/ti";

const FileUploadField = ({ label, placeholder, handleChange, multiple }) => {
  return (
    <div className="flex flex-col">
      <label className="text-gray-700 text-sm font-bold mb-1">{label}</label>
      <div className="flex items-center border border-orange-400 rounded-lg p-1">
        <div className="bg-orange-100 px-3 py-2 rounded-md flex flex-row ">
          <TiAttachment className="" />
          <label className="cursor-pointer text-zinc-800 text-xs font-medium">
            Select file
            <input
              type="file"
              className="hidden"
              multiple={multiple}
              accept="image/*"
              onChange={handleChange}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default FileUploadField;
