import { apiSlice } from "../apiSlice";

export const serviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // createPet: builder.mutation({
    //   query: (data) => ({
    //     url: "/api/v1/petparent/pets",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["PetProfile"],
    // }),
    getAllServiceProvider: builder.query({
      query: () => ({
        url: "/api/v1/serviceprovider/services/fetchAllServices",
        method: "GET",
      }),
      providesTags: ["ServiceProvider"],
    }),
    getAllAccommodation: builder.query({
      query: (id) => ({
        url: `/api/v1/pet-service/accomodation/admin/${id}`,
        method: "GET",
      }),
      providesTags: ["ServiceProvider"],
    }),
    getAllServices: builder.query({
      query: (id) => ({
        url: `/api/v1/pet-service/service-management/admin/${id}`,
        method: "GET",
      }),
      providesTags: ["ServiceProvider"],
    }),
    // getOnePet: builder.query({
    //   query: (id) => ({
    //     url: `/api/v1/petparent/pets/${id}`,
    //     method: "GET",
    //   }),
    //   providesTags: ["PetProfile"],
    // }),
    // deletePet: builder.mutation({
    //   query: (id) => ({
    //     url: `/api/v1/petparent/pets/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["PetProfile"],
    // }),
  }),
});

export const {
  useGetAllServicesQuery,
  useGetAllServiceProviderQuery,
  useGetAllAccommodationQuery,
  //   useGetPetProfileQuery,
  //   useCreatePetMutation,
  //   useGetOnePetQuery,
  //   useDeletePetMutation,
} = serviceApiSlice;
